import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Login from "../Login";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { GetAppSetup } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";

function Register() {

    const [showLogin, setShowLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmPassword, setShowconfirmPassword] = useState(false);

    const { IMG_URL, setSignin, sellerDomain, sellerId } = useContext(Context);

  
  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }

  }

  useEffect(() => {
    getAppSetup();
  }, [sellerId]);


    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const toggleconfirmPasswordVisibility = () => {
      setShowconfirmPassword(!showconfirmPassword);
    };

    const handleLoginClick = () => {
      setShowLogin(true);
    };

  return (

    <>
     {showLogin ? (
        <Login />
      ) : (
    <section className="register-section">
      <Container>

      <div className="LogIn">
          <div className="row bg-color me-0 ms-0">
            <div className="col-lg-7 p-0">
              <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
              <div className="loginlogo-div">
                  <img
                    className="loginlogo"
                    src={
                      IMG_URL +
                      appSetup?.image
                    }
                    alt="social_img"
                  />
                </div>

                <div className="col-xl-8 col-lg-7 col-md-7 ">
                  <div className="back_to_span">
                    <Link to={"/"}>
                      <p>Back to Home</p>
                    </Link>
                  </div>

                  <div className="heading-holder">
                    <h4>Register</h4>
                  </div>

                  <div className="log_in_form">
                    <Form>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control type="text" placeholder=" First Name" />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <Form.Control type="email" placeholder=" Email" />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="phone_number"
                          placeholder="Mobile"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                           
                            type={showPassword ? "text" : "password"}
                            placeholder=" Password"
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                            onClick={togglePasswordVisibility}
                              className="eye-icon"
                             
                              icon={showPassword ? faEyeSlash :  faEye }
                            />
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type={showconfirmPassword ? "text" : "password"}
                            placeholder="Re-enter Password"
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                            onClick={toggleconfirmPasswordVisibility}
                              className="eye-icon"
                              icon={showconfirmPassword ? faEyeSlash :  faEye }
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Form>

                    <div className="btn-holder">
                      <Link to={"/"}>
                        <Buttons className="SubmitBtn" text="  Create Account" />
                      </Link>
                    </div>

                    <div className="create_account mb-5">
                      <div className="login_text">
                        <p>
                          Already have an account? {/* <Link to={"/login"}> */}
                          <span
                            className="login_text"
                            onClick={handleLoginClick}
                          >
                            LOGIN
                          </span>
                          {/* </Link> */}
                        </p>
                      </div>
                    </div>

                    <div className="log_in_with">
                      <p>or login with netpurti</p>
                    </div>

                    <div className="social_img_holder">
                      <img
                        className="social_img"
                        src={
                          IMG_URL +
                          appSetup?.image
                        }
                        alt="social_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Container>
    </section>
 )}

    </>
  );
}

export default Register;
