import React, { useContext, useEffect, useState } from "react";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Success_Modalss from "../../Common_Component/Success_Modal/Success_Modalss";
import { useNavigate } from 'react-router-dom';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { GetAppSetup } from "../../../utils/apis/master/Master";

function SetNewPassword() {
  const { IMG_URL, setSignin, sellerDomain, sellerId } = useContext(Context);
  
  const [modalShow, setModalShow] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfPassword, setShowConfPassword] = useState(false);

    
  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }

  }

  useEffect(() => {
    getAppSetup();
  }, [sellerId]);

  const navigate = useNavigate();
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglessPasswordVisibility = () => {
    setShowConfPassword(!showconfPassword);
  };

  const onssubmits=()=>{
    setModalShow(true);

    setTimeout(() => {
      setModalShow(false);
      navigate('/');
    }, 3000);
  }

  return (
    <section className="setnew-section">
      <Container>
        <div className="LogIn">
          <div className="row bg-color me-0 ms-0">
            <div className="col-lg-7 p-0">
              <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
                <div className="loginlogo-div">
                  <img
                    className="loginlogo"
                    src={
                      IMG_URL +
                      appSetup?.image
                    }
                    alt="social_img"
                  />
                </div>

                <div className="col-lg-8">
                  <div className="back_to_span">
                    <Link to={"/"}>
                      <p>Back to Home</p>
                    </Link>
                  </div>

                  <div className="heading-holder">
                    <h4>Set New Password</h4>
                    <p>
                      Your new password must be different to previously used
                      password.
                    </p>
                  </div>

                  <div className="log_in_form">
                    <Form>
                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type={showconfPassword ? "text" : "password"}
                            placeholder="Enter your password"
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                            onClick={togglessPasswordVisibility}
                              className="eye-icon"
                              icon={showconfPassword ? faEyeSlash :  faEye }
                            />
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your confirm password"
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                            onClick={togglePasswordVisibility}
                              className="eye-icon"
                              icon={showPassword ? faEyeSlash :  faEye }
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Form>

                    <div className="btn-holder">
                      <Buttons
                      onClick={onssubmits}
                        className="SubmitBtn"
                        text=" Reset Password"
                      />
                    </div>

                    <div className="create_account">
                      <div className="login_text">
                        <p>
                          Didn't receive the email?
                          <span className="login_text">Click to resend</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Success_Modalss
        show={modalShow}
        onHide={() => setModalShow(false)}
        texts=" Your password has been reset successfully!"
      />
    </section>
  );
}

export default SetNewPassword;
