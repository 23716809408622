import React, { useContext, useEffect, useState } from "react";
import "./Login.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Buttons from "../Common_Component/Buttons/Buttons";
import Register from "./Register/Register";
import ResetPassWord from "./ResetPassWord/ResetPassWord";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../utils/context";
import { GetAppSetup } from "../../utils/apis/master/Master";

function Login() {
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const { IMG_URL, setSignin, sellerDomain, sellerId } = useContext(Context);
  const [showRegisterForm, setShowRegisterForm] = useState(false); // State variable for showing register form
  const [showPassword, setShowPassword] = useState(false);

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }

  }

  useEffect(() => {
    getAppSetup();
  }, [sellerId]);

  // Function to handle click event on "Create account?"
  const handleCreateAccountClick = () => {
    setShowRegisterForm(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {!showForgetPassword && !showRegisterForm && (
        <section className="login-section">
          <Container>
            <div className="LogIn">
              <div className="row bg-color me-0 ms-0">
                {/* Login Form */}
                <div className="col-lg-7 p-0 ">
                  <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
                    <div className="col-xl-8  col-lg-9 col-md-9">
                      <div className="loginlogo-div">
                        <img
                          className="loginlogo"
                          src={
                            IMG_URL +
                            appSetup?.image
                          }
                          alt="social_img"
                        />
                      </div>

                      <div className="back_to_span">
                        <Link to={"/"}>
                          <p>Back to Home</p>
                        </Link>
                      </div>

                      <div className="heading-holder">
                        <h4>Login</h4>
                      </div>

                      <div className="log_in_form">
                        <Form>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Control
                              type="phone"
                              placeholder="Email / Mobile Number"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="">
                            <div className="password-field-holder">
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                              />
                              <div className="eye-icon-holder ">
                                <FontAwesomeIcon
                                  onClick={togglePasswordVisibility}
                                  className="eye-icon"
                                  icon={showPassword ? faEyeSlash : faEye}
                                />
                              </div>
                            </div>
                            {/* <Link to={"/resetpassword"}> */}
                            <Form.Text
                              className="Forgot_password"
                              onClick={() => setShowForgetPassword(true)}
                            >
                              Forgot password?
                            </Form.Text>
                            {/* </Link> */}
                          </Form.Group>

                          <div className="btn-holder">
                            <Link to={"/"}>
                              <Buttons className="SubmitBtn" text="Login" />
                            </Link>
                          </div>

                          <div className="create_account mb-5">
                            {/* Attach click event handler */}
                            {/* <Link to={"/register"}> */}
                            <p onClick={handleCreateAccountClick}>
                              Create account ?
                            </p>
                            {/* </Link> */}
                          </div>
                        </Form>
                        <div className="log_in_with">
                          <p>or login with netpurti</p>
                        </div>

                        <div className="social_img_holder">
                          <img
                            className="social_img"
                            src={
                              IMG_URL +
                              appSetup?.image
                            }
                            alt="social_img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Container>
        </section>
      )}
      {showForgetPassword && <ResetPassWord />}
      {showRegisterForm && <Register />}
    </>
  );
}

export default Login;
