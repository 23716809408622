import React from 'react'
import AboutBanner from './about-banner/AboutBanner'
import OurStory from './our-story/OurStory'
import WhyChoose from './why-choose/WhyChoose'
import HappyCustomer from './happy-customer/HappyCustomer'
import CounterSec from './Counter-sec/CounterSec'
import { Helmet } from 'react-helmet'

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="About us" />

      </Helmet>
      <AboutBanner />
      <OurStory />
      <WhyChoose />
      <HappyCustomer />
      <CounterSec />
    </>
  )
}

export default AboutUs