import React, { useState, useEffect, useContext } from "react";
import ProductCart from "./ProductCart/ProductCart";
import SimilarPatterns from "./SimilarPatterns/SimilarPatterns"
import Reviews from "./Reviews/Reviews";
import Morebrands from "./Morebrands/Morebrands";
import { useParams } from "react-router-dom";
import { Context } from "../../utils/context";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
const ProductDescription = () => {
    const { IMG_URL, userdata, getData, deleteData, sellerId } = useContext(Context);

    const {
        getValues,
        setValue,
    } = useForm();
    const [product, setproduct] = useState([]);
    const [metaData, setMetaData] = useState()


    const [category, setCategory] = useState("");
    const { id } = useParams();
    console.log(id, "proid");
    const getProductDetails = async () => {
        const res = await getData(`/inquiry-website/home/all-product-details?seller_id=${sellerId}&id=${id}`);
        
        if (res?.success) {
            setproduct(res?.data)


            setValue(
                "product_id",
                res.data?.id
            );

            setValue(
                "mrp",
                res.data?.s_product_variant?.s_product_variant_attribute
                    ?.s_product_variant_attribute_details?.[0]
                    ?.s_p_v_a_d_price_a_stock?.mrp
            );
            setValue(
                "selling_price",
                res.data?.s_product_variant?.s_product_variant_attribute
                    ?.s_product_variant_attribute_details?.[0]
                    ?.s_p_v_a_d_price_a_stock?.selling_price
            );

            setValue(
                "stock",
                res.data?.s_product_variant?.s_product_variant_attribute
                    ?.s_product_variant_attribute_details?.[0]
                    ?.s_p_v_a_d_price_a_stock?.stock
            );

            setValue(
                "brand",
                res?.data?.s_products_supply_type?.s_custom?.name
            );
        }
    }
    useEffect(() => {
        getProductDetails();
    }, []);
    return (
        <>

            <Helmet>
                <title>Product Description</title>
                <meta name="description" content="Product Description" />
                <meta name="fb:admins" content="" />
                <meta name="fb:app_id" content="" />
                <meta name="og:price:sale_amount" content={getValues("selling_price")} />
                <meta name="og:price:standard_amount" content={getValues("mrp")} />               
                <meta name="product:availability" content={getValues("stock") > 0 ? "In Stock" : "Out of Stock"} />
                <meta name="product:brand" content={getValues("brand")} />
                <meta name="product:price:amount" content={getValues("selling_price")} />
                <meta name="product:price:currency" content={"INR"} />
                <meta name="product:item_group_id" content={getValues("product_id")} />

            </Helmet>

            <ProductCart
                id={id}
                sellerId={sellerId}
                IMG_URL={IMG_URL}
                product={product}
                setCategory={setCategory}
            />
            {/* <Reviews />
            <Morebrands />
            <SimilarPatterns heading={"Similar Patterns for you"} />
            <SimilarPatterns heading={"Crafted for you"} /> */}

        </>
    );
};

export default ProductDescription;
