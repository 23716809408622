import React, { useEffect, useState, useContext } from "react";
import "./Header.css";
import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faShoppingCart, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { GetAppSetup, GetWebHeader } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import Form from "react-bootstrap/Form";
import ErrorModal from "../../Common_Component/ErrorModalDynamic/ErrorModal"

const Header = () => {
  const removeItem = () => {
    localStorage.removeItem("outer4_category_id");
    // localStorage.removeItem('selectedItem');
  };

  const { signin, setSignin, sellerId, IMG_URL,
    domainError, setDomainError,
    domainModal, setDomainModal,sellerDomain } = useContext(Context);

  const navigate = useNavigate();
  const [headers, setHeaders] = useState([]);
  const getHeader = async () => {
    const res = await GetWebHeader(sellerId);
    if (res?.success) {
      setHeaders(res?.data);
    }
  };

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerDomain);
    if (res?.success) {
      setAppSetup(res?.data);
      updateFavicon(IMG_URL + res?.data?.image);
    }
  };

  const updateFavicon = (imageUrl) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = imageUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  useEffect(() => {
    getHeader();
    getAppSetup();
  }, [sellerId,]);
  return (
    <>
      <section
        className="header-section"
        style={{
          background: appSetup?.header_color,
        }}
      >
        <Container fluid className="p-0">
          <Container className="p-0">
            <div className="header-main roboto-family">
              <Navbar collapseOnSelect expand="lg" className=" p-0 m-0">
                <Navbar.Brand className="header-img">
                  <Link to={"/"}>
                    <img
                      className="header-logo"
                      src={IMG_URL + appSetup?.image}
                      alt="Logo"
                    />
                  </Link>
                </Navbar.Brand>

                {/* <div className="mobile_view_icon_holder">
                  <Nav className="navbMainTwo ">
                    <div className="xs-view">
                      <div className="mobile_view_icon ">
                        <Nav.Link
                          as={Link}
                          to="/product-cart-inner"
                          className="header-lists2 "
                        >
                          <button className="emailUs">email Us</button>
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav>
                </div> */}
                {/* <div className="mobile_view_icon_holder">
                  <Nav className="navbMainTwo ">
                    <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"
                        />
                      </div>
                    </Nav.Link>
                    <div className="lg_view">
                      <div className="mobile_view_icon ">
                        <Nav.Link
                          as={Link}
                          to="/product-cart-inner"
                          className="header-lists2 "
                        >
                          <button className="emailUs">email Us</button>
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav>
                </div> */}

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto navbMainOne">
                    {headers.length > 0 ? (
                      headers.map((value, index) => (
                        <>
                          {value.name === "Home" && (
                            <Link to={"/"} className="header-lists">
                              <Nav.Link
                                href="/"
                                className="header-lists"
                                style={{ color: appSetup?.header_font_color }}
                              >
                                Home
                              </Nav.Link>
                            </Link>
                          )}
                          {value.name === "About Us" && (
                            <Link
                              to={"/about-us"}
                              className="header-lists"
                              style={{ color: appSetup?.header_font_color }}
                            >
                              <Nav.Link
                                href="/about-us"
                                className="header-lists"
                              >
                                About Us
                              </Nav.Link>
                            </Link>
                          )}
                          {value.name === "Contact Us" && (
                            <Link
                              to={"/contact-us"}
                              className="header-lists"
                              style={{ color: appSetup?.header_font_color }}
                            >
                              <Nav.Link
                                href="/contact-us"
                                className="header-lists"
                              >
                                Contact Us
                              </Nav.Link>
                            </Link>
                          )}
                          {value.name === "FAQs" && (
                            <Link to={"/faq"} className="header-lists">
                              <Nav.Link
                                href="/faq"
                                className="header-lists"
                                style={{ color: appSetup?.header_font_color }}
                              >
                                FAQ
                              </Nav.Link>
                            </Link>
                          )}
                          {index === headers.length - 1 && (
                            <Link to={"/category"}>
                              <Nav.Link
                                href="/category"
                                className="header-lists"
                                style={{ color: appSetup?.header_font_color }}
                                onClick={removeItem}
                              >
                                Category
                              </Nav.Link>
                            </Link>
                          )}
                        </>
                      ))
                    ) : (
                      <>
                        <Link to={"/"}>
                          <Nav.Link
                            href="/"
                            className="header-lists"
                            style={{ color: appSetup?.header_font_color }}
                          >
                            Home
                          </Nav.Link>
                        </Link>
                        <Link to={"/about-us"}>
                          <Nav.Link
                            href="/about-us"
                            className="header-lists"
                            style={{ color: appSetup?.header_font_color }}
                          >
                            About
                          </Nav.Link>
                        </Link>

                        <Link to={"/category"}>
                          <Nav.Link
                            href="/category"
                            className="header-lists"
                            style={{ color: appSetup?.header_font_color }}
                            onClick={removeItem}
                          >
                            Category
                          </Nav.Link>
                        </Link>
                        <Link to={"/faq"}>
                          <Nav.Link
                            href="/faq"
                            className="header-lists"
                            style={{ color: appSetup?.header_font_color }}
                          >
                            FAQ
                          </Nav.Link>
                        </Link>
                        <Link to={"/contact-us"}>
                          <Nav.Link
                            href="/contact-us"
                            className="header-lists"
                            style={{ color: appSetup?.header_font_color }}
                          >
                            Contact
                          </Nav.Link>
                        </Link>
                      </>
                    )}
                  </Nav>

                  {/* <Nav className="navbMainTwo">
                    <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"
                        />
                      </div>
                    </Nav.Link>

                    <Nav.Link as={Link} to="/product-cart-inner" className="header-lists2 ">
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </Nav.Link>
                    <Nav.Link  as={Link} to="/productdescription" className="header-lists2 ">
                      <FontAwesomeIcon icon={farHeart} />
                    </Nav.Link>

                    <Nav.Link as={Link} to="/login" className="header-lists2 ">
                      <FontAwesomeIcon icon={faUser} />
                    </Nav.Link>
                  </Nav> */}

                  <Nav className="navbMainTwo desktop_view_icon_holder">
                    {/* <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"
                        />
                      </div>
                    </Nav.Link> */}
                    <div className="mobile_view_icon">
                      {/* <Nav.Link
                        className="header-lists2 "
                      >

                        Call : +91 7854534567
                      </Nav.Link> */}
                      {/* <Nav.Link as={Link} className="header-lists2 ">
                        <button className="emailUs">Email Us</button>
                      </Nav.Link> */}
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </Container>
        </Container>
        <ErrorModal show={domainModal}  onHide={() => setDomainModal(false)} heading={domainError} text={""} />
      </section>
    </>
  );
};

export default Header;
