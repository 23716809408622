import React, { useState, useEffect, useContext } from "react";
import "./Home_Banner.css";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Context } from "../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Banners } from "../../../utils/apis/master/Master";

function Home_Banner() {
  const { IMG_URL, userdata, getData, deleteData, sellerId, sellerDomain } =
    useContext(Context);
  console.log(sellerId);
  const [banner, setBanner] = useState([]);
  // Define an array of slide data

  const getBanners = async () => {
    const res = await Banners(sellerDomain);
    if (res?.success) {
      setBanner(res?.data);
    }
  };

  console.log(sellerDomain, "sellerDomain");

  useEffect(() => {
    getBanners();
  }, [sellerDomain]);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <>
      <div className="Home_Banner">
        <div className="Home_Banner_Card_Holder">
          <div className="row m-0 p-0">
            <div className="col-lg-12 col-xl-12 col-md-12">
              <div className="Home_Banner_Card_Slider_Holder">
                <Swiper
                  className="mySwiper"
                  spaceBetween={1}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  modules={[Pagination]}
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  {banner?.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <Banner_Card
                        imgSource={IMG_URL + slide.image}
                        imgClassName={"banner_main_img"}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="silder-btn">
                  <div
                    className="back-btn"
                    onClick={() => handleMainSliderClick("prev")}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                  </div>
                  <div
                    className="next-btn"
                    onClick={() => handleMainSliderClick("next")}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home_Banner;
