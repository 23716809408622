import React, { useContext, useEffect, useState } from "react";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Login from "../Login";
import SetNewPassword from "../SetNewPassword/SetNewPassword";
import CheckEmail from "../CheckEmail/CheckEmail";
import { Context } from "../../../utils/context";
import { GetAppSetup } from "../../../utils/apis/master/Master";

function ResetPassWord() {
  const [showSetNewPassword, setShowSetNewPassword] = useState(false);
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const { IMG_URL, setSignin, sellerDomain, sellerId } = useContext(Context);

  
  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }

  }

  useEffect(() => {
    getAppSetup();
  }, [sellerId]);

  const handleSubmit = () => {
    setShowCheckYourEmail(true);
    setTimeout(() => {
      setShowCheckYourEmail(false);
      setShowSetNewPassword(true);
    }, 3000); // Wait for 3 seconds before showing Set_New_Password
  };
  return (
    <>
      <section className="reset-section">
        <Container>
          <div className="LogIn">
            {!showSetNewPassword && !showLogin && !showCheckYourEmail && (
              <div className="row bg-color me-0 ms-0">
                <div className="col-lg-7 p-0">
                  <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
                    <div className="loginlogo-div">
                      <img
                        className="loginlogo"
                        src={
                          IMG_URL +
                          appSetup?.image
                        }
                        alt="social_img"
                      />
                    </div>

                    <div className="col-xxl-8  col-xl-8 col-lg-8 col-md-9 ">
                      <div className="back_to_span">
                        <Link to={"/"}>
                          <p>Back to Home</p>
                        </Link>
                      </div>

                      <div className="heading-holder">
                        <h4>Reset Your Password</h4>
                        <p>
                          You will receive an email from us requesting you to
                          reset your password.
                        </p>
                      </div>

                      <div className="log_in_form">
                        <Form>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Control
                              type="phone"
                              placeholder="Email or Mobile Number"
                            />
                          </Form.Group>
                        </Form>

                        <div className="btn-holder">
                          <Buttons
                            className="SubmitBtn"
                            onClick={handleSubmit}
                            text=" Submit"
                          />
                        </div>

                        <div className="create_account">
                          {/* On click, set showLogin state to true */}
                          {/* <Link to={"/login"}> */}
                          <span
                            className="cancel-text"
                            onClick={() => setShowLogin(true)}
                          >
                            CANCEL
                          </span>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showCheckYourEmail && <CheckEmail />}
            {showSetNewPassword && <SetNewPassword />}
            {showLogin && <Login />}
          </div>
        </Container>
      </section>
    </>
  );
}

export default ResetPassWord;
