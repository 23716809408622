import React, { useState, useEffect, useContext } from "react";
import "./AboutBanner.css";
import { Context } from "../../../utils/context";
import { aboutBanner } from "../../../utils/apis/common/Common";
const AboutBanner = () => {
  const [getBanner, setBanner] = useState();
  const { getData, IMG_URL, sellerId, sellerDomain } = useContext(Context);
  const getProject = async (data) => {
    const res = await aboutBanner(sellerDomain);
    if (res?.success) {
      setBanner(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getProject();
  }, [sellerDomain]);
  return (
    <>
      <section className="about-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto mt-3">
              <img src={IMG_URL + getBanner?.image} className="about-banner" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
